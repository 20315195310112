import { ReactNode } from 'react'
import { AuthenticationStatus } from '../types'
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams
} from 'react-router-dom'
import LoadingPage from '../../../views/LoadingPage/LoadingPage'

type ProtectedRouteProps = {
  loginState: AuthenticationStatus
  children?: ReactNode
}

export function ProtectedRoute({ loginState, children }: ProtectedRouteProps) {
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  if (loginState === AuthenticationStatus.Loading) {
    return <LoadingPage />
  }

  if (loginState === AuthenticationStatus.LoggedOut) {
    console.log('Redirecting to login')
    setSearchParams((prev) => {
      prev.set('redirect', pathname.toString())
      return prev
    })
    return <Navigate to={`/login/?${searchParams.toString()}`} replace />
  }

  return children ? children : <Outlet />
}
