import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import LoadingIcon from '../../../assets/pill-loader.gif'
import { useTranslation } from 'react-i18next'

import styles from './Shared.module.scss'
import {
  useRequestStatus,
  statusRequestQueryKey
} from '../queries/useRequestStatus'

import { Button, Icon } from 'mysaiva-web-components'
import { LoginContent } from './LoginContent'
import { apiClient } from '../../../services/api'
import { queryClient } from '@utils/queries'
import { OtpPhrase, LoginTokens } from '../../../types/api'

type EmailStepSuccessArgs = OtpPhrase & {
  email?: string
}

type LoginStatusArgs = {
  emailStepSuccessArgs: EmailStepSuccessArgs
  onLoginSuccess: (tokens: LoginTokens) => void
  onFailedRequest: () => void
  onMoveToOTP: () => void
}

export function LoginStepStatus(args: LoginStatusArgs) {
  const { emailStepSuccessArgs, onFailedRequest, onLoginSuccess, onMoveToOTP } =
    args

  const { t } = useTranslation()
  const [isDeclined, setIsDeclined] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams({
    email: emailStepSuccessArgs.email || '',
    user_id: emailStepSuccessArgs.user_id?.toString() || '',
    platform: 'web'
  })

  const { data, isLoading } = useRequestStatus(searchParams)

  async function expireToken() {
    onFailedRequest()
    await apiClient.patch('v2/auth/fcm_status', {
      user_id: searchParams.get('user_id'),
      status: 'Expired'
    })
  }

  useEffect(() => {
    const handleParam = (param: string) => {
      if (searchParams.get(param) === 'true') {
        const p = new URLSearchParams(searchParams)
        p.delete(param)
        setSearchParams(p)
        if (param === 'expired') {
          expireToken()
        }
      }
    }

    handleParam('expired')
    handleParam('declined')
  }, [searchParams])

  function failedRequest() {
    onFailedRequest()
  }

  useEffect(
    function () {
      if (isLoading) return
      //make switch
      switch (data?.status) {
        case 'Declined': {
          setIsDeclined(true)
          setSearchParams({ ...searchParams, declined: 'true' })
          const declineTimeout = setTimeout(function () {
            failedRequest()
          }, 5000)
          return () => {
            clearTimeout(declineTimeout)
            setIsDeclined(false)
          }
        }
        case 'Accepted':
          if (data.access_token && data.refresh_token) {
            onLoginSuccess({
              access_token: data.access_token,
              refresh_token: data.refresh_token
            })
          }
          break
        case 'Failed':
          failedRequest()
          break
        case 'Expired':
          failedRequest()
          break
        default:
          break
      }
    },
    [data, isLoading]
  )

  function handleBackToEmail() {
    failedRequest()
  }

  function handleMoveToOTP() {
    queryClient.resetQueries({ queryKey: statusRequestQueryKey })
    onMoveToOTP()
  }

  const Buttons = (
    <>
      <Button
        type="text"
        onClick={handleBackToEmail}
        data-testid="login-back-btn"
      >
        {t('login.loginStepEmail.backToEmail')}
      </Button>
      <Button
        type="text"
        onClick={handleMoveToOTP}
        data-testid="login-move-to-otp-btn"
      >
        {t('login.loginStepEmail.sendOTP')}
      </Button>
    </>
  )

  function otpMessage() {
    return (
      <div>
        <div className={styles.logo}>
          <img src={LoadingIcon} width={72} />
        </div>
        <h4>{t('login.mobileOTP.title')}</h4>
        <p>{t('login.mobileOTP.message')}</p>
        {Buttons}
      </div>
    )
  }

  function otpDeclined() {
    return (
      <div>
        <div className={styles.declineIcon}>
          <Icon icon="CrossCircle" color="#DC362E" size={30} />
        </div>
        <h4>{t('login.mobileOTP.declinedTitle')}</h4>
        <p>{t('login.mobileOTP.declinedMessage')}</p>
        {Buttons}
      </div>
    )
  }

  return (
    <LoginContent title="" subtitle="" footer={null}>
      <div className={styles.pageWrapper}>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            {isDeclined ? otpDeclined() : otpMessage()}
          </div>
        </div>
      </div>
    </LoginContent>
  )
}
